import axios from "axios";

const instance = axios.create({
  baseURL: "https://prod.medra.co.in",
});

export default instance;

// https://prod.medra.co.in
// http://localhost:8005
// http://3.111.67.126:8005