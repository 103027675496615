import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Snackbar, Typography, CircularProgress, Button } from '@material-ui/core';
import { Alert } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { ROUTES } from "../../Route/Routes.constants";
import { getListData } from '../common/Api.service';
import BackDrop from '../common/BackDrop';
import Axios from "../common/Axios";

const useStyles = makeStyles((theme) => ({
	selectStyle: {
		cursor: "pointer",
		margin: "1.5rem 2rem 0.5rem 2rem",
		padding: "0.5rem",
		fontWeight: 700,
		borderRadius: "8px",
		border: "2px solid #00D100",
	},
	fundContainer: {
		border: "1px solid #282828",
		borderRadius: "8px",
		padding: "1rem",
		marginBottom: "1rem",
		cursor: "pointer",
	},
	fundValue: {
		textAlign: "center",
		fontSize: "0.875rem",
		color: "#222",
		fontWeight: 700,
		"@media screen and (max-width: 450px)": {
		  fontSize: "0.75rem",
		},
	},
	btnStyle: {
		fontSize: "1rem",
		color: "#FFF",
		fontWeight: 700,
		padding: "0.5rem 2rem",
		cursor: "pointer",
		display: "flex",
		justifyContent: "center",
		margin: "auto",
		marginTop: "1.5rem",
		"@media screen and (max-width: 550px)": {
			fontSize: "0.75rem",
			padding: "0.5rem 1rem",
		},
	},
	btnContainer: {
		display: 'flex',
		justifyContent: 'space-around',
		"@media screen and (max-width: 400px)": {
			flexDirection: 'column',
		},
	},
}))

function UnlistedStocksLayout() {

  const [loading, setLoading] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [hover, setHover] = useState(-1);
  const [btnHover, setBtnHover] = useState(-1);
  const [allData, setAllData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [countUS, setCountUS] = useState(1);

  const classes = useStyles();

  const unlistedData = useSelector((state) => state.landing.unlistedStocks);
  const profileData = useSelector((state) => state.profile.login);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  }

  useEffect(() => {
	window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
	if (isEmpty(unlistedData)) getListData(dispatch);
  }, []);

  useEffect(() => {
	setAllData(unlistedData);
	setFilterData([...unlistedData].slice(0, 20));
  }, [unlistedData]);

  const SortOptions = [
    {
      id: 1,
      val: "Price - High to Low",
    },
    {
      id: 2,
      val: "Price - Low to High",
    },
    {
      id: 3,
      val: "Quantity - High to Low",
    },
    {
      id: 4,
      val: "Quantity - Low to High",
    },
  ];

  const sortParams = (data, type, key) => {
	let arr = [];
    arr = data.slice().sort(function (a, b) {
      if (type === "LTH") {
        return Number(a?.[key]) - Number(b?.[key]);
      } else {
        return Number(b?.[key]) - Number(a?.[key]);
      }
    });
    return arr;
  }

  const handleSelect = (e) => {
	const id = e.target.value;
	let data = allData;
	if (isEmpty(allData)) return;
	if (id == 1) {
		data = sortParams(data, "HTL", "Price");
	}
	if (id == 2) {
		data = sortParams(data, "LTH", "Price");
	}
	if (id == 3) {
		data = sortParams(data, "HTL", "Min Quantity");
	}
	if (id == 4) {
		data = sortParams(data, "LTH", "Min Quantity");
	}
	setAllData(data);
	setFilterData(data.slice(0, 20));
	setCountUS(1);
  }

  const handleUnlistedStockInterest = async (data, type) => {
	let obj = {
		type,
		...data
	};
	const reqBody = {
		title: "Details of Unlisted Stocks Query :- ",
		name: profileData.firstName + " " + profileData.lastName,
		email: profileData.email,
		mobile: profileData.phoneNumber,
		bondData: obj,
	};
	if (profileData.loggedIn) {
		setLoading(true);
		try {
			await Axios.post("/send/show-interest", reqBody);
			setOpenAlert(true);
			setAlertMsg(
				"Thank You For Your Unlisted Stock Interest. Our team will get in touch with you soon!"
			);
			setAlertType("success");
		} catch (e) {
			const { data } = e;
			setOpenAlert(true);
			setAlertMsg("Something went wrong!");
			setAlertType("error");
		} finally {
			setLoading(false);
			setTimeout(() => {
				setOpenAlert(false);
			}, 6000);
		}
	} else {
		navigate(ROUTES.LOGIN, { replace: true });
	}
  }

  const USListData = ({ item, ind }) => {
	return (
		<Box
			onMouseEnter={() => setHover(ind)}
			onMouseLeave={() => setHover(-1)}
			style={{
				border:
				hover === ind ? "3px solid #00008B" : "1px solid #282828",
			}}
			key={ind}
			className={classes.fundContainer}
		>
			<Typography style={{ color: '#10BBE5', textAlign: 'center', fontSize: '1.2rem', letterSpacing: '1px', fontWeight: 700 }}>
				{item?.Name}
			</Typography>
			<hr style={{ margin: '1.2rem 0 1.8rem 0' }} />
			<Box style={{ display: 'flex', justifyContent: 'space-around' }}>
				{
					Object.entries(item).filter(([k, v]) => k.toLowerCase() !== 'name').map(([key, value]) => (
						<>
							<Box style={{ display: 'flex', justifyContent: 'space-around' }}>
								<Box
									style={{
										display: "flex",
										flexDirection: "column",
										marginTop: "6px",
									}}
								>
									<Typography
										style={{
											textAlign: "center",
											fontSize: "0.75rem",
											marginBottom: "4px",
											color: "#585858",
											fontWeight: 400,
										}}
									>
										{key}
									</Typography>
									<Typography className={classes.fundValue}>
										{
											key.toLowerCase() === "price" ? `Rs ${value}` : value
										}
									</Typography>
								</Box>
							</Box>
						</>
					))
				}
			</Box>
			<Box className={classes.btnContainer}>
				<Button
					style={{
						backgroundColor: "#0000D1",
						opacity: btnHover === ind + 0.1 ? 0.6 : 1,
						color: "#FFF",
						margin: '1.5rem auto',
						marginBottom: '0.5rem',
						display: 'flex',

					}}
					className={classes.btnStyle}
					onMouseEnter={() => setBtnHover(ind + 0.1)}
					onMouseLeave={() => setBtnHover(-1)}
					onClick={() => handleUnlistedStockInterest(item, "BUY")}
				>
					Buy Interest
				</Button>
				<Button
					style={{
						backgroundColor: "#FF0000",
						opacity: btnHover === ind + 0.2 ? 0.6 : 1,
						color: "#FFF",
						margin: '1.5rem auto',
						marginBottom: '0.5rem',
						display: 'flex',

					}}
					className={classes.btnStyle}
					onMouseEnter={() => setBtnHover(ind + 0.2)}
					onMouseLeave={() => setBtnHover(-1)}
					onClick={() => handleUnlistedStockInterest(item, "SELL")}
				>
					Sell Interest
				</Button>
			</Box>
		</Box>
	)
  }

  const isValidNext = allData.slice(20 * countUS, 20 * (countUS + 1)).length === 0;

  return (
	<>
		<BackDrop open={loading} />
		<Snackbar
			style={{ 
				width: '100%',
				margin: window.innerWidth < 900 ? '10px 0' : '10px 13%', 
				display: 'flex', 
				justifyContent: 'center' 
			}} 
			open={openAlert} 
			anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
		>
			<Alert severity={alertType}>{alertMsg}</Alert>
		</Snackbar>
		<Box pb={4} />
		<Box
			style={{ display: "flex", justifyContent: "flex-end" }}
		>
			<select className={classes.selectStyle} onChange={handleSelect}>
			<option value={-1}>sort by relevance</option>
			{SortOptions.map((item) => (
				<option value={item.id}>{item.val}</option>
			))}
			</select>
		</Box>
		<Box style={{ padding: "2rem" }}>
			{
				filterData.length && filterData.map((item, ind) => (
					<Box key={ind}>
						<USListData item={item} ind={ind} />
						{
							ind + 1 === filterData.length ? (
							<Box style={{ display: 'flex', gap: '10px', justifyContent: 'center', marginTop: '1rem' }}>
								<Button 
									style={{ color: 'white', backgroundColor: countUS === 1 ? 'gray' : 'black', opacity: btnHover === -2 ? 0.6 : 1, textTransform: 'capitalize', fontWeight: 'bold', borderRadius: '8px', cursor: 'pointer', height: '40px', minWidth: '70px', marginTop: '5px' }}
									onMouseEnter={() => setBtnHover(-2)}
									onMouseLeave={() => setBtnHover(-1)}
									onClick={() => {
										setCountUS(prev => prev - 1);
										setFilterData(allData.slice(20 * (countUS - 2), 20 * (countUS - 1)));
										window.scrollTo(0, 0);
									}}
									disabled={countUS === 1}
								>
									back
								</Button>
								<p style={{ color: 'black' }}>
									<span style={{ fontSize: '0.75rem' }}>page</span> 
									{' '}
									<strong>{countUS}</strong>
								</p>
								<Button 
									style={{ color: 'white', backgroundColor: isValidNext ? 'gray' : 'black', opacity: btnHover === -3 ? 0.6 : 1, textTransform: 'capitalize', fontWeight: 'bold', borderRadius: '8px', cursor: 'pointer', height: '40px', minWidth: '70px', marginTop: '5px' }}
									onMouseEnter={() => setBtnHover(-3)}
									onMouseLeave={() => setBtnHover(-1)}
									onClick={() => {
										setCountUS(prev => prev + 1);
										setFilterData(allData.slice(20 * countUS, 20 * (countUS + 1)));
										window.scrollTo(0, 0);
									}}
									disabled={isValidNext}
								>
									next
								</Button>
							</Box>
							
							) : null
						}
					</Box>
				))
			}
			{
				isEmpty(allData) && (
					<Box
						style={{
							height: "60vh",
							backgroundColor: "#ADD8E6",
							borderRadius: "1rem",
							display: "flex",
							justifyContent: "center",
							flexDirection: "column",
						}}
					>
						<Box style={{ display: "flex", justifyContent: "center" }}>
							<WarningAmberIcon
								style={{ width: "120px", height: "120px" }}
								fontSize="100px"
								color="warning"
							/>
						</Box>
						<Box style={{ display: "flex", justifyContent: "center" }}>
							<CircularProgress
								style={{
									margin: "auto",
									marginTop: "2rem",
									textAlign: "center",
								}}
								color="secondary"
							/>
						</Box>
						<Typography
							style={{
								fontSize: "2rem",
								textAlign: "center",
								marginTop: "2rem",
								fontWeight: 700,
								color: "#282828",
							}}
						>
							Fetching Unlisted Stocks Details
						</Typography>
					</Box>
				)
			}
		</Box>
	</>
  )
}

export default UnlistedStocksLayout;
