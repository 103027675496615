import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { getListData } from "../common/Api.service";
import BackDrop from "../common/BackDrop";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { ROUTES } from "../../Route/Routes.constants";
import Axios from "../common/Axios";
import { Alert } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  scrollContainer: {
    backgroundColor: "#F5F5F5",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    position: "fixed",
    overflow: "scroll",
    height: "70px",
    width: "100%",
    zIndex: 100,
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
  nestedScroll: {
    backgroundColor: "#FFF",
    marginTop: "15px",
    marginLeft: "1.5rem",
    marginRight: "1.5rem",
    width: "100%",
    whiteSpace: "nowrap",
    border: "1px solid #282828",
    padding: "0.5rem 0.875rem",
    borderRadius: "12px",
    fontWeight: 500,
    color: "#FF8300",
    cursor: "pointer",
  },
  fundContainer: {
    border: "1px solid #282828",
    borderRadius: "8px",
    padding: "1rem",
    marginBottom: "1rem",
    cursor: "pointer",
  },
  topSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: '1rem',
  },
  fundName: {
    width: "100%",
    fontWeight: 700,
    margin: "auto",
    fontSize: '1.5rem',
    "@media screen and (max-width: 900px)": {
      fontSize: '1.2rem',
    },
    "@media screen and (max-width: 600px)": {
      fontSize: '1rem',
    },
  },
  fundDetails: {
    width: "100%",
    display: "grid",
    gridAutoFlow: "row",
    gridTemplateColumns: "repeat(6, 1fr)",
    justifyItems: "center",
    marginBottom: "4px",
    gap: "0.5rem",
    "@media screen and (max-width: 800px)": {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
  fundValue: {
    textAlign: "center",
    fontSize: "0.875rem",
    color: "#222",
    fontWeight: 700,
    "@media screen and (max-width: 450px)": {
      fontSize: "0.75rem",
    },
  },
  selectStyle: {
    cursor: "pointer",
    margin: "1.5rem 2rem 0.5rem 2rem",
    padding: "0.5rem",
    fontWeight: 700,
    borderRadius: "8px",
    border: "2px solid #00D100",
  },
  btnStyle: {
    backgroundColor: "#14bdad",
    textTransform: "capitalize",
    fontSize: "1rem",
    color: "#FFF",
    fontWeight: 700,
    padding: "0.5rem 2rem",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    marginTop: "1.5rem",
  },
  imgStyle: {
    width: '200px',
    borderRadius: '8px',
    "@media screen and (max-width: 900px)": {
      width: '150px',
    },
    "@media screen and (max-width: 600px)": {
      width: '100px',
    },
    "@media screen and (max-width: 450px)": {
      width: '80px',
    },
  }
}));

const BondLayout = () => {
  const classes = useStyles();
  const [hover, setHover] = useState(-1);
  const [btnHover, setBtnHover] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [issuerType, setIssuerType] = useState("All");
  const [issuerTypeOption, setIssuerTypeOption] = useState([]);
  const [alertType, setAlertType] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [allMFData, setAllMFData] = useState([]);
  const [filterMFData, setFilterMFData] = useState([]);
  const [countMF, setCountMF] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mfData = useSelector((state) => state.landing.MF);
  const profileData = useSelector((state) => state.profile.login);

  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const SortOptions = [
    {
      id: 1,
      val: "1D - High to Low",
    },
    {
      id: 2,
      val: "1D - Low to High",
    },
    {
      id: 3,
      val: "1W - High to Low",
    },
    {
      id: 4,
      val: "1W - Low to High",
    },
    {
      id: 5,
      val: "1M - High to Low",
    },
    {
      id: 6,
      val: "1M - Low to High",
    },
    {
      id: 7,
      val: "1Y - High to Low",
    },
    {
      id: 8,
      val: "1Y - Low to High",
    },
    {
      id: 9,
      val: "3Y - High to Low",
    },
    {
      id: 10,
      val: "3Y - Low to High",
    },
    {
      id: 11,
      val: "5Y - High to Low",
    },
    {
      id: 12,
      val: "5Y - Low to High",
    },
    {
      id: 13,
      val: "AUM - High to Low",
    },
    {
      id: 14,
      val: "AUM - Low to High",
    },
  ];

  const performMountOps = (data) => {
    let arr = ["All"];
    let fund = [];
    Object.keys(data).map((key) => {
      arr.push(key);
      data[key]?.map((item) => {
        fund.push(item);
      });
    });
    setIssuerTypeOption(arr);
    setAllMFData(fund);
    setFilterMFData(fund.slice(0, 20));
    setCountMF(1);
  };

  const bondDataApi = async () => {
    if (isEmpty(mfData)) {
      setLoading(true);
      try {
        await getListData(dispatch);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    }
  };

  const FilterIssuerType = (val) => {
    let arr = [];
    if (val === "All") {
      Object.keys(mfData).map((key) => {
        mfData[key]?.map((item) => {
          arr.push(item);
        });
      });
      setAllMFData(arr);
      setFilterMFData(arr.slice(0, 20));
      setCountMF(1);
    } else {
      setFilterMFData([...mfData?.[val]].slice(0, 20));
      setAllMFData(mfData?.[val]);
      setCountMF(1);
    }
  };

  const percentValToNum = (val) => {
    let num = val?.replaceAll("%", "");
    return Number(num);
  };

  const sortPercentData = (fund, type, key) => {
    let arr = [];
    arr = fund.sort(function (a, b) {
      if (type === "LTH") {
        return percentValToNum(a?.[key]) - percentValToNum(b?.[key]);
      } else {
        return percentValToNum(b?.[key]) - percentValToNum(a?.[key]);
      }
    });
    return arr;
  }

  const handleSelect = (e) => {
    const id = e.target.value;
    let fund = [];
    if (issuerType !== "All") {
      Object.keys(mfData).map((key) => {
        if (key === issuerType) {
          mfData[key]?.map((item) => {
            fund.push(item);
          });
        }
      });
    } else {
      Object.keys(mfData).map((key) => {
        mfData[key]?.map((item) => {
          fund.push(item);
        });
      });
    }
    if (id == 1) {
      fund = sortPercentData(fund, "HTL", "1D");
    }
    if (id == 2) {
      fund = sortPercentData(fund, "LTH", "1D");
    }
    if (id == 3) {
      fund = sortPercentData(fund, "HTH", "1W");
    }
    if (id == 4) {
      fund = sortPercentData(fund, "LTH", "1W");
    }
    if (id == 5) {
      fund = sortPercentData(fund, "HTH", "1M");
    }
    if (id == 6) {
      fund = sortPercentData(fund, "LTH", "1M");
    }
    if (id == 7) {
      fund = sortPercentData(fund, "HTH", "1Y");
    }
    if (id == 8) {
      fund = sortPercentData(fund, "LTH", "1Y");
    }
    if (id == 9) {
      fund = sortPercentData(fund, "HTH", "3Y");
    }
    if (id == 10) {
      fund = sortPercentData(fund, "LTH", "3Y");
    }
    if (id == 11) {
      fund = sortPercentData(fund, "HTH", "5Y");
    }
    if (id == 12) {
      fund = sortPercentData(fund, "LTH", "5Y");
    }
    if (id == 13) {
      fund = sortPercentData(fund, "HTH", "AUM");
    }
    if (id == 14) {
      fund = sortPercentData(fund, "LTH", "AUM");
    }
    setAllMFData(fund);
    setFilterMFData(fund.slice(0, 20));
    setCountMF(1);
  };

  const handleBondInterest = async (data) => {
    let obj = data;
    const reqBody = {
      title: "Details of Mutual Fund Query :- ",
      name: profileData.firstName + " " + profileData.lastName,
      email: profileData.email,
      mobile: profileData.phoneNumber,
      bondData: obj,
    };
    if (profileData.loggedIn) {
      setLoading(true);
      try {
        await Axios.post("/send/show-interest", reqBody);
        setOpenAlert(true);
        setAlertMsg(
          "Thank You For Your Bond Interest. Our team will get in touch with you soon!"
        );
        setAlertType("success");
      } catch (e) {
        const { data } = e;
        setOpenAlert(true);
        setAlertMsg("Something went wrong!");
        setAlertType("error");
      } finally {
        setLoading(false);
        setTimeout(() => {
          setOpenAlert(false);
        }, 6000);
      }
    } else {
      navigate(ROUTES.LOGIN, { replace: true });
    }
  };

  const MFListData = ({ item, ind }) => {
    return (
      <Box
          onMouseEnter={() => setHover(ind)}
          onMouseLeave={() => setHover(-1)}
          style={{
            border:
              hover === ind ? "3px solid #00008B" : "1px solid #282828",
          }}
          key={ind}
          className={classes.fundContainer}
        >
          <Box className={classes.topSection}>
            <Box style={{ margin: 'auto 0' }}>
              <img className={classes.imgStyle} src={item?.Image} />
            </Box>
            <Box style={{ margin: 'auto' }}>
              <Box className={classes.fundName}>{item?.Name}</Box>
            </Box>
          </Box>
          <hr color="#C5C5C5" />
          <Box className={classes.fundDetails}>
            {Object.entries(item)
              .filter(
                ([k, v]) =>
                  k.toLowerCase() !== "name" &&
                  k.toLowerCase() !== "image"
              )
              .map(([key, value], ind) => (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "6px",
                  }}
                >
                  <Typography
                    style={{
                      textAlign: "center",
                      fontSize: "0.75rem",
                      marginBottom: "4px",
                      color: "#585858",
                      fontWeight: 400,
                    }}
                  >
                    {key}
                  </Typography>
                  <Typography className={classes.fundValue}>
                    {
                      key.toLowerCase() === "aum" ? `${value} Cr` : `${value}%`
                    }
                  </Typography>
                </Box>
              ))}
          </Box>
          <Button
            style={{
              backgroundColor: btnHover && "#14bdad",
              opacity: btnHover === ind ? 0.6 : 1,
              color: btnHover === ind ? "#282828" : "#FFF",
            }}
            className={classes.btnStyle}
            onMouseEnter={() => setBtnHover(ind)}
            onMouseLeave={() => setBtnHover(-1)}
            onClick={() => handleBondInterest(item)}
          >
            Show Interest
          </Button>
        </Box>
    )
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [issuerType]);

  useEffect(() => {
    bondDataApi();
  }, []);

  useEffect(() => {
    performMountOps(mfData);
  }, [mfData]);

  const isValidNext = allMFData.slice(20 * countMF, 20 * (countMF + 1)).length === 0;

  return (
    <Box>
      <BackDrop open={loading} />
      <Snackbar
        style={{
          width: "100%",
          margin: "20px 0",
          display: "flex",
          justifyContent: "center",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={openAlert}
      >
        <Alert severity={alertType}>{alertMsg}</Alert>
      </Snackbar>
      <KeyboardDoubleArrowUpIcon
        onClick={() => window.scrollTo(0, 0)}
        style={{
          position: "fixed",
          bottom: "2px",
          right: "1px",
          cursor: "pointer",
        }}
        color="primary"
        fontSize="large"
      />
      <Box className={classes.scrollContainer}>
        {issuerTypeOption?.map((item, ind) => (
          <Box
            style={{
              backgroundColor: issuerType === item ? "#000075" : "#FFF",
            }}
            onClick={() => {
              setIssuerType(item);
              FilterIssuerType(item);
            }}
            className={classes.nestedScroll}
            key={ind}
          >
            {item}
          </Box>
        ))}
      </Box>
      <Box pb={9} />
      <Box
        key={issuerType}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <select className={classes.selectStyle} onChange={handleSelect}>
          <option value={-1}>sort by relevance</option>
          {SortOptions.map((item) => (
            <option value={item.id}>{item.val}</option>
          ))}
        </select>
      </Box>
      <Box style={{ padding: "2rem" }}>
        {filterMFData.length ? (
          filterMFData.map((item, ind) => (
            <Box key={ind}>
              <MFListData item={item} ind={ind} />
              {
                ind + 1 === filterMFData.length ? (
                  <Box style={{ display: 'flex', gap: '10px', justifyContent: 'center', marginTop: '1rem' }}>
                    <Button 
                      style={{ color: 'white', backgroundColor: countMF === 1 ? 'gray' : 'black', opacity: btnHover === -2 ? 0.6 : 1, textTransform: 'capitalize', fontWeight: 'bold', borderRadius: '8px', cursor: 'pointer', height: '40px', minWidth: '70px', marginTop: '5px' }}
                      onMouseEnter={() => setBtnHover(-2)}
                      onMouseLeave={() => setBtnHover(-1)}
                      onClick={() => {
                        setCountMF(prev => prev - 1);
                        setFilterMFData(allMFData.slice(20 * (countMF - 2), 20 * (countMF - 1)));
                        window.scrollTo(0, 0);
                      }}
                      disabled={countMF === 1}
                    >
                      back
                    </Button>
                    <p style={{ color: 'black' }}>
                      <span style={{ fontSize: '0.75rem' }}>page</span> 
                      {' '}
                      <strong>{countMF}</strong>
                    </p>
                    <Button 
                      style={{ color: 'white', backgroundColor: isValidNext ? 'gray' : 'black', opacity: btnHover === -3 ? 0.6 : 1, textTransform: 'capitalize', fontWeight: 'bold', borderRadius: '8px', cursor: 'pointer', height: '40px', minWidth: '70px', marginTop: '5px' }}
                      onMouseEnter={() => setBtnHover(-3)}
                      onMouseLeave={() => setBtnHover(-1)}
                      onClick={() => {
                        setCountMF(prev => prev + 1);
                        setFilterMFData(allMFData.slice(20 * countMF, 20 * (countMF + 1)));
                        window.scrollTo(0, 0);
                      }}
                      disabled={isValidNext}
                    >
                      next
                    </Button>
                  </Box>
                  
                ) : null
              }
            </Box>
          ))
        ) : (
          <Box
            style={{
              height: "60vh",
              backgroundColor: "#ADD8E6",
              borderRadius: "1rem",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <WarningAmberIcon
                style={{ width: "120px", height: "120px" }}
                fontSize="100px"
                color="warning"
              />
            </Box>
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress
                style={{
                  margin: "auto",
                  marginTop: "2rem",
                  textAlign: "center",
                }}
                color="secondary"
              />
            </Box>
            <Typography
              style={{
                fontSize: "2rem",
                textAlign: "center",
                marginTop: "2rem",
                fontWeight: 700,
                color: "#282828",
              }}
            >
              Fetching Mutual Fund Details
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BondLayout;
