export const ROUTES = Object.freeze({
	HOME: '/',
	LOGIN: '/login',
	PROFILE: '/profile',
	ABOUT_US: '/about-us',
	CONTACT_US: '/contact-us',
	BONDS: '/bonds',
	INSURANCE: '/insurance',
	MUTUAL_FUNDS: '/mutual-funds',
	UNLISTED_STOCKS: '/unlisted-stocks',
	INTER_CORPORATE_BONDS: '/inter-corporate-bonds',
	INTER_CORPORATE_DEPOSIT: '/intercorporate-deposit',
	BOND_EXPLAINATION: '/bond-explaination',
	PERKS_ON_BOND_INVESTING: '/perks-on-bond-investing',
	DECISION_MAKING_ON_INVESTMENT: '/decision-making-on-investment',
	GET_LIST: '/lists',
});