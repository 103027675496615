import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import MaintenanceLayout from '../common/Maintenance.Layout';

function InsuranceLayout() {

  useEffect(() => {
	window.scrollTo(0, 0);
  }, []);

  return (
	<Box>
	  <MaintenanceLayout />
	</Box>
  )
}

export default InsuranceLayout;
