import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import BusinessLoanImg from '../../assets/BusinessLoanImg.jpg';
import { ROUTES } from '../../Route/Routes.constants';

const useStyles = makeStyles(theme => ({
	container: {
		backgroundColor: '#fdfdfd',
		padding: '1.5rem',
		paddingLeft: '1.5rem',
		'@media screen and (max-width: 700px)': {
			padding: '1.5rem',
		},
	},
	container2: {
		marginTop: '1rem',
	},
	header: {
		fontSize: '2.5rem',
		fontWeight: 700,
		marginBottom: '2rem',
		'@media screen and (max-width: 899px)': {
			fontSize: '2rem',
		},
		'@media screen and (max-width: 500px)': {
			fontSize: '1.5rem',	
		},
		'@media screen and (max-width: 330px)': {
			fontSize: '1.2rem',	
		},
	},
	header2: {
		fontFamily: 'Roboto, sans-serif',
		fontSize: '1.5rem',
		color: '#282828',
		fontWeight: 700,
		'@media screen and (max-width: 400px)': {
			fontSize: '1.3rem',
		},
		'@media screen and (max-width: 300px)': {
			fontSize: '1.1rem',
		}
	},
	icdContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		'@media screen and (max-width: 899px)': {
			flexDirection: 'column',
			alignItems: 'center',
		},
	},
	imgStyle: {
		width: '50%',
		'@media screen and (max-width: 899px)': {
			width: '60%',
		},
		'@media screen and (max-width: 600px)': {
			width: '70%',
		},
		'@media screen and (max-width: 500px)': {
			width: '90%',
		},
	},
	icdDetails: {
		backgroundColor: '#FFCE66',
		width: '100%',
		margin: 'auto',
		borderRadius: '1rem',
		padding: '1.5rem',
		'@media screen and (min-width: 1050px)': {
			width: '500px',
		},
		'@media screen and (max-width: 899px)': {
			width: '60%',
			height: 'auto',
			marginTop: '1rem',
		},
		'@media screen and (max-width: 700px)': {
			width: '80%',
		},
		'@media screen and (max-width: 550px)': {
			width: '90%',
		},
		'@media screen and (max-width: 450px)': {
			padding: '1rem',
			width: '90%',
			fontSize: '0.6rem',
		},
	},
	icdNestedStyle: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: '0.5rem',
	},
	icdTextStyle: {
		'@media screen and (max-width: 450px)': {
			fontSize: '0.9rem',
		},
		'@media screen and (max-width: 340px)': {
			fontSize: '0.8rem',
		},
	},
	btnStyle4: {
		margin: 'auto',
		marginTop: '2rem',
		display: 'flex',
		fontWeight: 700,
		padding: '0.5rem 1.5rem',
		textTransform: 'capitalize',
		borderRadius: '8px',
		cursor: 'pointer',
		letterSpacing: '1px',
		color: '#FFF',
		backgroundColor: '#474ebb',
	},
}));

const ICDContainer = ({ icdData = {}, icdPage = false }) => {

  const [hover, setHover] = useState(false);
  const [IcdData1, setIcdData1] = useState([]);
  const [IcdData2, setIcdData2] = useState([]);
  const [IcdReturnVal, setIcdReturnVal] = useState("");
  const classes = useStyles();
  const navigate = useNavigate();

  const performMountOps = (data) => {
	let arr1 = [], arr2 = [];
	Object.keys(data)?.map((key, index) => {
		if (index < 2) arr2.push({ key: key, value: data[key] });
		else if (index === 2) setIcdReturnVal(data[key]);
		else arr1.push({ key: key, value: data[key] });
	});
	setIcdData1(arr1);
	setIcdData2(arr2);
  }

  useEffect(() => {
	performMountOps(icdData);
  }, [icdData]);

  return (
	  <Box className={ icdPage ? classes.container2 : classes.container}>
		<Box style={{ display: 'flex', justifyContent: 'space-between' }}>
			<Typography className={ icdPage ? classes.header2 : classes.header}>
				{ icdPage ? 'Best Business Loan Interest Rates Available' : 'InterCorporate Deposit' }
			</Typography>
		</Box>
		{
			icdPage && (
				<hr color='#1616FF' style={{ width: '100px', margin: '1rem 0' }} />
			)
		}
		<Box className={classes.icdContainer}>
			<img className={classes.imgStyle} src={BusinessLoanImg} alt="Business Loan" />
			{
				IcdData1.length && IcdData2.length ? (
					<Box style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between', gap: '1rem' }}>
						<Box style={{ height: !icdPage && '150px' }} className={classes.icdDetails}>
							<Typography style={{ fontSize: '1.3rem', fontWeight: 600 }}>
								Why us for ICD?
							</Typography>
							<Box style={{ marginTop: '1.5rem', marginBottom: '1rem' }}>
								{
									IcdData2.map((item, ind) => (
										<Box key={ind} className={classes.icdNestedStyle}>
											<Typography className={classes.icdTextStyle} style={{ fontWeight: 500, color: '#696969' }}>
												{item.key}
											</Typography>
											<Typography className={classes.icdTextStyle} style={{ fontWeight: 700 }}>
												{item.value}
											</Typography>
										</Box>
									))
								}
							</Box>
							<Box style={{ textAlign: 'center', fontWeight: 700 }}>Get upto {IcdReturnVal} p.a. return by doing ICD with us</Box>
						</Box>
						<Box style={{ height: !icdPage && '250px' }} className={classes.icdDetails}>
							<Typography style={{ fontSize: '1.3rem', fontWeight: 600 }}>
								Business Loan
							</Typography>
							<Box style={{ marginTop: '1.5rem', marginBottom: '1rem' }}>
								{
									IcdData1.map((item, ind) => (
										<Box key={ind} className={classes.icdNestedStyle}>
											<Typography className={classes.icdTextStyle} style={{ fontWeight: 500, color: '#696969' }}>
												{item.key}
											</Typography>
											<Typography className={classes.icdTextStyle} style={{ fontWeight: 700 }}>
												{item.value}
											</Typography>
										</Box>
									))
								}
							</Box>
							{
								!icdPage && (
									<Button
										style={{ backgroundColor: hover && '#474ebb', opacity: hover && 0.8, color: hover && '#000' }}
										onMouseEnter={() => setHover(true)}
										onMouseLeave={() => setHover(false)}
										onClick={() => navigate(ROUTES.INTER_CORPORATE_DEPOSIT)}
										className={classes.btnStyle4}
									>
										Explore Now
									</Button>
								)
							}
						</Box>
					</Box>
				) : (
					<Box style={{ height: '360px', backgroundColor: '#ADD8E6' }} className={classes.icdDetails}>
						<Box style={{ display: 'flex', justifyContent: 'center' }}>
							<WarningAmberIcon style={{ width: '120px', height: '120px' }} fontSize='100px' color='warning' />
						</Box>
						<Box style={{ display: 'flex', justifyContent: 'center' }}>
							<CircularProgress style={{ margin: 'auto', marginTop: '2rem', textAlign: 'center' }} color="secondary" />
						</Box>
						<Typography style={{ fontSize: '2rem', textAlign: 'center', marginTop: '2rem', fontWeight: 700, color: '#282828' }}>
							Fetching Bond Details
						</Typography>
					</Box>
				)
			}
			
		</Box>
	</Box>
  )
}

export default ICDContainer;
