import { Box } from "@material-ui/core";

export const getFilterSentence = (val) => {
	if (!val || typeof(val) !== 'string') return "";
	const arr = val.split(" ");
	let finalArr = [];
	let len = 0;
	let s = "";
	for (let i = 0; i < arr.length; i++) {
		len += arr[i].length;
		if (len <= 16) s = s + arr[i] + " ";
		else {
			finalArr.push(s);
			s = arr[i];
			len = 0;
		}
	}
	if (s) finalArr.push(s);
	return (
		finalArr.map((item, key) => (
			<Box style={{ textAlign: "center" }} key={key}>
				{item}
			</Box>
		))
	)
  }