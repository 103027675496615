import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Snackbar, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import RatingIcon from '../../assets/RatingIcon.svg'
import LockIcon from '@mui/icons-material/Https';
import { getListData } from '../common/Api.service';
import BackDrop from '../common/BackDrop';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { getFilterSentence } from '../common/Common.service';
import { ROUTES } from '../../Route/Routes.constants';
import Axios from '../common/Axios';
import { Alert } from '@mui/material';

const useStyles = makeStyles((theme) => ({
	scrollContainer: {
		backgroundColor: '#F5F5F5',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		position: 'fixed',
		overflow: 'scroll',
		height: '70px',
		width: '100%',
		zIndex: 100,
		'&::-webkit-scrollbar': { 
			width: 0, 
		}
	},
	nestedScroll: {
		backgroundColor: '#FFF',
		marginTop: '15px',
		marginLeft: '1.5rem',
		marginRight: '1.5rem',
		width: '100%',
		whiteSpace: 'nowrap',
		border: '1px solid #282828',
		padding: '0.5rem 0.875rem',
		borderRadius: '12px',
		fontWeight: 500,
		color: '#FF8300',
		cursor: 'pointer',
	},
	bondContainer: {
		border: '1px solid #282828',
		borderRadius: '8px',
		padding: '1rem',
		marginBottom: '1rem',
		cursor: 'pointer',
	},
	topSection: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	bondName: {
		width: '90%',
		fontWeight: 700,
		margin: 'auto',
	},
	bondRating: {
		position: 'absolute',
		marginTop: '-63px',
		fontWeight: 700,
		marginLeft: '30px',
		marginRight: '30px'
	},
	bondRating1: {
		position: 'absolute',
		marginTop: '-40px',
		marginLeft: '16px',
		margin: 'auto',
		height: '75px',
		width: '75px',
		justifyContent: 'center',
		fontWeight: 500,
		fontSize: '0.5rem',
	},
	bondDetails: {
		width: '100%',
		display: 'grid',
		gridAutoFlow: 'row',
		gridTemplateColumns: 'repeat(6, 1fr)',
		justifyItems: 'center',
		marginBottom: '4px',
		gap: '0.5rem',
		'@media screen and (max-width: 800px)': {
			gridTemplateColumns: 'repeat(3, 1fr)',
		}
	},
	bondValue: {
		textAlign: 'center',
		fontSize: '0.875rem',
		color: '#222',
		fontWeight: 700,
		'@media screen and (max-width: 450px)': {
			fontSize: '0.75rem',
		}
	},
	selectStyle: {
		cursor: 'pointer',
		margin: '1.5rem 2rem 0.5rem 2rem',
		padding: '0.5rem',
		fontWeight: 700,
		borderRadius: '8px',
		border: '2px solid #00D100',
	},
	btnStyle: {
		backgroundColor: "#14bdad",
		textTransform: 'capitalize',
		fontSize: "1rem",
		color: "#FFF",
		fontWeight: 700,
		padding: "0.5rem 2rem",
		cursor: "pointer",
		display: "flex",
		justifyContent: "center",
		margin: "auto",
		marginTop: "1.5rem",
	}
}))

const BondLayout = () => {
  const classes = useStyles();
  const [hover, setHover] = useState(-1);
  const [btnHover, setBtnHover] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [issuerType, setIssuerType] = useState('All');
  const [issuerTypeOption, setIssuerTypeOption] = useState([]);
  const [alertType, setAlertType] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [allBondData, setAllBondData] = useState([]);
  const [filterBondData, setFilterBondData] = useState([]);
  const [countBond, setCountBond] = useState(1);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bondData = useSelector((state) => state.landing.bond);
  const profileData = useSelector((state) => state.profile.login);

  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  }

  const yearToNum = {
	JAN: 1,
	FEB: 2,
	MAR: 3,
	APR: 4,
	MAY: 5,
	JUN: 6,
	JUL: 7,
	AUG: 8,
	SEP: 9,
	OCT: 10,
	NOV: 11,
	DEC: 12,
  };

  const SortOptions = [
	{
		id: 1,
		val: 'Investment - High to Low',
	},
	{
		id: 2,
		val: 'Investment - Low to High',
	},
	{
		id: 3,
		val: 'Coupon Rate from High to Low',
	},
	{
		id: 4,
		val: 'Coupon Rate - Low to High',
	},
	{
		id: 5,
		val: 'Tenure - High to Low',
	},
	{
		id: 6,
		val: 'Tenure - Low to High',
	},
	{
		id: 7,
		val: 'Safety - High to Low',
	},
	{
		id: 8,
		val: 'Safety - Low to High',
	},
	{
		id: 9,
		val: 'YTM - High to Low',
	},
	{
		id: 10,
		val: 'YTM - Low to High',
	},
  ];

  const safetyPriorityList = {
	'AAA': 1,
	'BBB': 2,
	'SOV': 3,
	'AA+': 4,
	'AA': 5,
	'AA-': 6,
	'A+': 7,
	'A': 8,
	'A-': 9,
  }

  const performMountOps = (data) => {
	let arr = ['All'];
	let bonds = [];
	Object.keys(data).map((key) => {
		arr.push(key);
		data[key]?.map((item) => {
			bonds.push(item);
		})
	})
	setIssuerTypeOption(arr);
	setAllBondData(bonds);
	setFilterBondData(bonds.slice(0, 20));
  }

  const bondDataApi = async () => {
	if (isEmpty(bondData)) {
		setLoading(true);
		try {
			await getListData(dispatch);
		} catch (e) {
		} finally {
			setLoading(false);
		}
	}
  }

  const FilterIssuerType = (val) => {
	let arr = [];
	if (val === 'All') {
		Object.keys(bondData).map((key) => {
			bondData[key]?.map((item) => {
				arr.push(item);
			})
		})
		setAllBondData(arr);
		setFilterBondData(arr.slice(0, 20));
		setCountBond(1);
	}
	else {
		setAllBondData(bondData?.[val]);
		setFilterBondData([...bondData?.[val]].slice(0, 20));
		setCountBond(1);
	}
  }

  const getStringForIP = (val) => {
	if(!val?.length) return;
	if (val.length === 1) {
		return val[0];
	}
	let str = "";
	let arr = [];
	val.map((item, k) => {
		if (k === 2) {
			arr.push(str);
			str = "";
		}
		str = str + item + " ";
	})
	if (str) arr.push(str);
	return (
		<Box style={{ textAlign: "center" }}>
			{arr[0]}
			<br />
			{arr[1]}
		</Box>
	)
  }

  const CalculatePxForRating = (val) => {
	if (val?.length === 3) return '22px';
	else if (val?.length === 2) return '28px';
	return '32px';
  }

  const investmentValToNum = (val) => {
	let num = val.toLowerCase();
	if (num.includes('lac', 'lacs')) {
		num = num.replaceAll('lacs', '').replace('lac', '').trim();
		num = Number(num) * 100000;
	} else if (num.includes('cr', 'crs')) {
		num = num.replaceAll('crs', '').replaceAll('cr', '').trim();
		num = Number(num) * 10000000;
	} else {
		num = Number(val.trim());
	}
	return num;
  }

  const percentValToNum = (val) => {
	if (val === '--') return 0;
	let num = val.replaceAll('%', '');
	return Number(num);
  }

  const tenureValToNum = (val) => {
	let data = val.replaceAll(" ","").split("-");
	let dd = Number(data?.[0]);
	let mm = yearToNum?.[data?.[1]?.toUpperCase()];
	let yy = Number(data?.[2]);
	return [yy, mm, dd];
  }

  const safetyValueFilter = (val) => {
	let data = val?.[0].trim()?.toUpperCase();
	if (data.includes('AAA')) return safetyPriorityList?.['AAA'];
	else if (data.includes('AA+')) return safetyPriorityList?.['AA+'];
	else if (data.includes('AA')) return safetyPriorityList?.['AA'];
	else if (data.includes('A+')) return safetyPriorityList?.['A+'];
	else if (data.includes('A')) return safetyPriorityList?.['A'];
	else if (data.includes('A-')) return safetyPriorityList?.['A-'];
	else if (data.includes('SOV')) return safetyPriorityList?.['SOV'];
	else if (data.includes('BBB')) return safetyPriorityList?.['BBB'];
	return 100;
  }

  const sortInvestmentData = (bonds, type) => {
	let arr = [];
	arr = bonds.sort(function(a, b) {
		if (type === 'LTH') {
			return investmentValToNum(a?.["Min. Investment"]) - investmentValToNum(b?.["Min. Investment"]);
		} else {
			return investmentValToNum(b?.["Min. Investment"]) - investmentValToNum(a?.["Min. Investment"]);
		}
	});
	return arr;
  }

  const sortCouponData = (bonds, type) => {
	let arr = [];
	arr = bonds.sort(function(a, b) {
		if (type === 'LTH') {
			return percentValToNum(a?.["Coupon Rate"]) - percentValToNum(b?.["Coupon Rate"]);
		} else {
			return percentValToNum(b?.["Coupon Rate"]) - percentValToNum(a?.["Coupon Rate"]);
		}
	});
	return arr;
  }

  const sortTenureData = (bonds, type) => {
	let arr = [];
	arr = bonds.sort(function(a, b) {
		const x = tenureValToNum(a?.["Maturity Date"]);
		const y = tenureValToNum(b?.["Maturity Date"]);
		if (type === 'LTH') {
			if (x[0] === y[0] && x[1] === y[1]) return x[2] - y[2];
			else if (x[0] === y[0]) return x[1] - y[1];
			return x[0] - y[0];
		} else {
			if (x[0] === y[0] && x[1] === y[1]) return y[2] - x[2];
			else if (x[0] === y[0]) return y[1] - x[1];
			return y[0] - x[0];
		}
	});
	return arr;
  }

  const sortSafetyData = (bonds, type) => {
	let arr = [];
	arr = bonds.sort(function(a, b) {
		if (type === 'LTH') {
			return safetyValueFilter(b?.["Rating"]) - safetyValueFilter(a?.["Rating"]);
		} else {
			return safetyValueFilter(a?.["Rating"]) - safetyValueFilter(b?.["Rating"]);
		}
	});
	return arr;
  }

  const sortYTMData = (bonds, type) => {
	let arr = [];
	arr = bonds.sort(function(a, b) {
		if (type === 'LTH') {
			return percentValToNum(a?.["YTM"]) - percentValToNum(b?.["YTM"]);
		} else {
			return percentValToNum(b?.["YTM"]) - percentValToNum(a?.["YTM"]);
		}
	});
	return arr;
  }

  const handleSelect = (e) => {
	const id = e.target.value;
	let bonds = [];
	if (issuerType !== 'All') {
		Object.keys(bondData).map((key) => {
			if (key === issuerType) {
				bondData[key]?.map((item) => {
					bonds.push(item);
				})
			}
		});
	} else {
		Object.keys(bondData).map((key) => {
			bondData[key]?.map((item) => {
				bonds.push(item);
			})
		});
	}
	if (id == 1) {
		bonds = sortInvestmentData(bonds, "HTL");
	}
	if (id == 2) {
		bonds = sortInvestmentData(bonds, "LTH");
	}
	if (id == 3) {
		bonds = sortCouponData(bonds, "HTH");
	}
	if (id == 4) {
		bonds = sortCouponData(bonds, "LTH");
	}
	if (id == 5) {
		bonds = sortTenureData(bonds, "HTH");
	}
	if (id == 6) {
		bonds = sortTenureData(bonds, "LTH");
	}
	if (id == 7) {
		bonds = sortSafetyData(bonds, "HTH");
	}
	if (id == 8) {
		bonds = sortSafetyData(bonds, "LTH");
	}
	if (id == 9) {
		bonds = sortYTMData(bonds, "HTH");
	}
	if (id == 10) {
		bonds = sortYTMData(bonds, "LTH");
	}
	setAllBondData(bonds);
	setFilterBondData(bonds.slice(0, 20));
	setCountBond(1);
  }

  const handleBondInterest = async (data) => {
	let obj = data;
	Object.entries(data).map(([key, value]) => {
		if (Array.isArray(value)) {
			obj = {
				...obj,
				[key]: value.join(" "),
			}
		}
	})
	const reqBody = {
		title: "Details of Bond Query :- ",
		name: profileData.firstName + " " +profileData.lastName,
		email: profileData.email,
		mobile: profileData.phoneNumber,
		bondData: obj,
	}
	if (profileData.loggedIn) {
		setLoading(true);
		try {
			await Axios.post('/send/show-interest', reqBody);
			setOpenAlert(true);
			setAlertMsg('Thank You For Your Bond Interest. Our team will get in touch with you soon!');
			setAlertType('success');
		} catch (e) {
			const { data } = e;
			setOpenAlert(true);
			setAlertMsg('Something went wrong!');
			setAlertType('error');
		} finally {
			setLoading(false);
			setTimeout(() => {
				setOpenAlert(false);
			}, 6000);
		}
	} else {
		navigate(ROUTES.LOGIN, { replace: true });
	}
  }

  const BondDataList = ({ item, ind }) => {
	return (
		<Box 
			onMouseEnter={() => setHover(ind)} 
			onMouseLeave={() => setHover(-1)}
			style={{ border: hover === ind ? "3px solid #00008B" : "1px solid #282828" }} 
			key={ind} 
			className={classes.bondContainer}
		>
			<Box className={classes.topSection}>
				<Box className={classes.bondName}>
					{item?.Name}
				</Box>
				<Box>
					<img src={RatingIcon} />
					<Box>
						<Typography style={{ marginLeft: CalculatePxForRating(item?.Rating?.[0]) }} className={classes.bondRating}>
							{item?.Rating?.[0]?.toUpperCase()}
						</Typography>
						<Typography className={classes.bondRating1}>
							{item?.Rating?.[1]?.toUpperCase()}
						</Typography>
					</Box>
				</Box>
			</Box>
			<hr color="#C5C5C5" />
			<Box className={classes.bondDetails}>
				{
					Object.entries(item).filter(([k, v]) => k.toLowerCase() !== 'name' && k.toLowerCase() !== 'rating' && k.toLowerCase() !== 'price').map(([key, value], ind) => (
						<Box style={{ display: 'flex', flexDirection: 'column', marginTop: '6px' }}>
							<Typography
								style={{ textAlign: 'center', fontSize: '0.75rem', marginBottom: '4px', color: '#585858', fontWeight: 400 }}
							>
								{key}
							</Typography>
							<Typography className={classes.bondValue}>
								{
									key.toLowerCase() === 'ip' ? getStringForIP(value) : (
										key.toUpperCase() === 'YTM' && !profileData.phoneNumber ? (
											<Box style={{ cursor: 'pointer' }} onClick={() => navigate(ROUTES.LOGIN)}>
												<LockIcon style={{ color: "#EC9B26" }} />
												<Typography style={{ fontSize: '0.75rem', color: "#EC9B26", fontWeight: 700, }}>
													SignUp
												</Typography>
											</Box>
										)  : getFilterSentence(value)
									)
								}
							</Typography>
						</Box>
					))
				}
			</Box>
			<Button 
				style={{ backgroundColor: btnHover && "#14bdad", opacity: btnHover === ind ? 0.6 : 1, color: btnHover === ind ? "#282828" : "#FFF" }}
				className={classes.btnStyle} 
				onMouseEnter={() => setBtnHover(ind)}
				onMouseLeave={() => setBtnHover(-1)}
				onClick={() => handleBondInterest(item)}
			>
				Show Interest
			</Button>
		</Box>
	)
  }

  useEffect(() => {
	window.scrollTo(0, 0);
  }, [issuerType]);

  useEffect(() => {
	bondDataApi();
  }, []);

  useEffect(() => {
	performMountOps(bondData);
  }, [bondData]);

  const isValidNext = allBondData.slice(20 * countBond, 20 * (countBond + 1)).length === 0;

  return (
	<Box>
		<BackDrop open={loading} />
		<Snackbar
			  style={{
				  width: '100%',
				  margin: '20px 0',
				  display: 'flex',
				  justifyContent: 'center',
			  }}
			  anchorOrigin={{
				  vertical: "top",
				  horizontal: "center"
			  }}
			  open={openAlert}
		  >
			  <Alert severity={alertType}>{alertMsg}</Alert>
		  </Snackbar>
		<KeyboardDoubleArrowUpIcon 
			onClick={() => window.scrollTo(0, 0)}
			style={{ position: 'fixed', bottom: '2px', right: '1px', cursor: 'pointer' }}
			color='primary'
			fontSize='large'
		/>
		<Box className={classes.scrollContainer}>
			{
				issuerTypeOption?.map((item, ind) => (
					<Box 
						style={{ backgroundColor: issuerType === item ? '#000075' : '#FFF' }} 
						onClick={() => {
							setIssuerType(item);
							FilterIssuerType(item);
						}} 
						className={classes.nestedScroll} 
						key={ind}
					>
						{item}
					</Box>
				))
			}
		</Box>
		<Box pb={9} />
		<Box key={issuerType} style={{ display: 'flex', justifyContent: 'flex-end' }}>
			<select className={classes.selectStyle} onChange={handleSelect}>
				<option value={-1}>sort by relevance</option>
				{
					SortOptions.map((item) => (
						<option value={item.id}>{item.val}</option>
					))
				}
			</select>
		</Box>
		<Box style={{ padding: '2rem' }}>
			{
				filterBondData.length ? filterBondData?.map((item, ind) => (
					<Box key={ind}>
						<BondDataList item={item} ind={ind} />
						{
							ind + 1 === filterBondData.length ? (
								<Box style={{ display: 'flex', gap: '10px', justifyContent: 'center', marginTop: '1rem' }}>
									<Button 
										style={{ color: 'white', backgroundColor: countBond === 1 ? 'gray' : 'black', opacity: btnHover === -2 ? 0.6 : 1, textTransform: 'capitalize', fontWeight: 'bold', borderRadius: '8px', cursor: 'pointer', height: '40px', minWidth: '70px', marginTop: '5px' }}
										onMouseEnter={() => setBtnHover(-2)}
										onMouseLeave={() => setBtnHover(-1)}
										onClick={() => {
											setCountBond(prev => prev - 1);
											setFilterBondData(allBondData.slice(20 * (countBond - 2), 20 * (countBond - 1)));
											window.scrollTo(0, 0);
										}}
										disabled={countBond === 1}
									>
										back
									</Button>
									<p style={{ color: 'black' }}>
										<span style={{ fontSize: '0.75rem' }}>page</span> 
										{' '}
										<strong>{countBond}</strong>
									</p>
									<Button 
										style={{ color: 'white', backgroundColor: isValidNext ? 'gray' : 'black', opacity: btnHover === -3 ? 0.6 : 1, textTransform: 'capitalize', fontWeight: 'bold', borderRadius: '8px', cursor: 'pointer', height: '40px', minWidth: '70px', marginTop: '5px' }}
										onMouseEnter={() => setBtnHover(-3)}
										onMouseLeave={() => setBtnHover(-1)}
										onClick={() => {
											setCountBond(prev => prev + 1);
											setFilterBondData(allBondData.slice(20 * countBond, 20 * (countBond + 1)));
											window.scrollTo(0, 0);
										}}
										disabled={isValidNext}
									>
										next
									</Button>
								</Box>
								
							) : null
						}
					</Box>
				)) : (
					<Box style={{ height: '60vh', backgroundColor: '#ADD8E6', borderRadius: '1rem', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
						<Box style={{ display: 'flex', justifyContent: 'center' }}>
							<WarningAmberIcon style={{ width: '120px', height: '120px' }} fontSize='100px' color='warning' />
						</Box>
						<Box style={{ display: 'flex', justifyContent: 'center' }}>
							<CircularProgress style={{ margin: 'auto', marginTop: '2rem', textAlign: 'center' }} color="secondary" />
						</Box>
						<Typography style={{ fontSize: '2rem', textAlign: 'center', marginTop: '2rem', fontWeight: 700, color: '#282828' }}>
							Fetching Bond Details
						</Typography>
					</Box>
				)
			}
		</Box>
	</Box>
  )
}

export default BondLayout;
