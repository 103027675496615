import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import EngineeringIcon from '@mui/icons-material/Engineering';

const useStyles = makeStyles((theme) => ({
	main: {
		height: '100vh',
		margin: 'auto',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		gap: '1rem',
		backgroundColor: '#7EC8E3',
		padding: '1rem',
	},
	title: {
		fontSize: '2rem',
		fontWeight: 700,
		textAlign: 'center',
		color: '#0000FF',
	}
}))

function MaintenanceLayout() {

  const classes = useStyles();

  return (
	<Box className={classes.main}>
	  <Box style={{ marginTop: '-5rem', marginLeft: 'auto', marginRight: 'auto' }}>
	  	<EngineeringIcon style={{ width: '250px', height: '250px', marginLeft: '3rem' }} color='warning' />
	  </Box>
	  <Typography className={classes.title}>
		Under Maintenance
	  </Typography>
	</Box>
  )
}

export default MaintenanceLayout;
