import { ROUTES } from "./Routes.constants";
import LandingPage from "../component/LandingPage/LandingPage.Layout";
import Profile from "../component/Profile/Profile.Layout";
import Signup from "../component/Profile/Signup.Layout";
import AboutUs from "../component/AboutUs/AboutUs";
import QAWrapper from '../component/QABonds/QAWrapper';
import ICDPageLayout from "../component/ICDPage/ICDPage.Layout";
import ContactUsLayout from "../component/ContactUs/ContactUs.Layout";
import BondLayout from "../component/Bond/Bond.Layout";
import InsuranceLayout from "../component/Insurance/Insurance.Layout";
import MutualFundLayout from "../component/MutualFund/MutualFund.Layout";
import UnlistedStocksLayout from "../component/UnlistedStocks/UnlistedStocks.Layout";

export const APP_ROUTES = [
	{
		path: ROUTES.HOME,
		exact: true,
		element: <LandingPage />,
	},
	{
		path: ROUTES.LOGIN,
		exact: true,
		element: <Signup />,
	},
	{
		path: ROUTES.PROFILE,
		exact: true,
		element: <Profile />,
	},
	{
		path: ROUTES.ABOUT_US,
		exact: true,
		element: <AboutUs />,
	},
	{
		path: `/:id`,
		exact: true,
		element: <QAWrapper />
	},
	{
		path: ROUTES.INTER_CORPORATE_DEPOSIT,
		exact: true,
		element: <ICDPageLayout />
	},
	{
		path: ROUTES.CONTACT_US,
		exact: true,
		element: <ContactUsLayout />
	},
	{
		path: ROUTES.BONDS,
		exact: true,
		element: <BondLayout />
	},
	{
		path: ROUTES.INSURANCE,
		exact: true,
		element: <InsuranceLayout />
	},
	{
		path: ROUTES.MUTUAL_FUNDS,
		exact: true,
		element: <MutualFundLayout />
	},
	{
		path: ROUTES.UNLISTED_STOCKS,
		exact: true,
		element: <UnlistedStocksLayout />
	},
]