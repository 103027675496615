import { Route, Routes, useLocation } from 'react-router-dom';
import Footer from '../component/Footer/Footer.Layout';
import { ROUTES } from './Routes.constants';

const AppRoutes = ({ appRoutes }) => {

  const location = useLocation();
  const path = location?.pathname;

  return (
    <>
      <Routes>
        {appRoutes.map((routesProps, key) => (
          <Route key={key} {...routesProps} />
        ))}
      </Routes>
      {
        window.innerWidth < 900 || (path !== ROUTES.LOGIN && path !== ROUTES.PROFILE) ? (
          <Footer />
        ) : null
      }
    </>
  );
};

export default AppRoutes;
