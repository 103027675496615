import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Snackbar, Typography, makeStyles } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Alert } from '@mui/material';
import ContactUsImg from '../../assets/ContactUsImg.png'
import BackDrop from '../common/BackDrop';
import Axios from '../common/Axios';
import { ROUTES } from '../../Route/Routes.constants';

const useStyles = makeStyles(() => {
	return {
		main: {
			backgroundColor: '#5C5CFF',
			minHeight: '100vh',
		},
		container: {
			display: 'flex',
			flexDirection: 'row',
			width: '100%',
		},
		imgStyle: {
			position: 'absolute',
			width: "50%",
			height: "100%",
			'@media screen and (max-width: 899px)': {
				display: 'none !important',
			},
		},
		contactContainer: {
			marginLeft: '52%',
			padding: '2rem',
			overflow: 'scroll',
			width: '100%',
			'@media screen and (max-width: 899px)': {
				margin: 'auto',
			},
			'@media screen and (min-width: 600px) and (max-width: 750px)': {
				width: '70%',
			},
			'@media screen and (min-width: 750px) and (max-width: 899px)': {
				width: '60%',
			},
			'@media screen and (min-width: 1100px)': {
				width: '40%',
			},
		},
		boxContainer: {
			backgroundColor: '#FFFFFF',
			borderRadius: '8px',
			width: '100%',
		},
		boxChild: {
			marginTop: '1rem',
			padding: '1rem',
		},
		header: {
			textAlign: 'center',
			fontWeight: 700,
			fontSize: '1.125rem',
		},
		subtitle: {
			textAlign: 'center',
			fontWeight: 600,
			fontSize: '0.875rem',
			marginTop: '0.3rem',
			color: '#979797',
		},
		inputStyle: {
			marginTop: '1.3rem',
			width: 'calc(100% - 30px)',
			height: '40px',
			border: '1px solid #ccc',
			transition: 'all 0.3s ease',
			padding: '0 15px',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		},
		prefix: {
			position: 'absolute',
			marginTop: '2.1rem',
			marginLeft: '1rem',
			fontSize: '0.8rem'
		},
		link: {
			cursor: 'pointer',
			color: '#0000FF',
			fontSize: '0.875rem',
		},
		textareaStyle: {
			height: '80px',
			padding: '15px',
			width: 'calc(100% - 30px)',
			resize: 'none',
			marginTop: '1.3rem',
			border: '1px solid #ccc',
			transition: 'all 0.3s ease',
			'&::-webkit-scrollbar': { 
				width: 0, 
			}	
		},
		btnStyle: {
			margin: 'auto',
			marginTop: '1.5rem',
			display: 'flex',
			fontWeight: 700,
			padding: '0.5rem 1.5rem',
			textTransform: 'capitalize',
			borderRadius: '8px',
			cursor: 'pointer',
			letterSpacing: '1px',
			color: '#FFF',
			backgroundColor: '#474ebb',
		},
	}
});

const allProduct = ["InterCorporate Deposit", "Bonds", "Insurance", "Mutual Funds", "Unlisted Stocks"];

const ContactUsLayout = () => {
	const classes = useStyles();
	const [alertType, setAlertType] = useState('');
	const [openAlert, setOpenAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState('');
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState('');
	const [mobile, setMobile] = useState('');
	const [subject, setSubject] = useState('');
	const [description, setDescription] = useState('');
	const [selectProduct, setSelectProduct] = useState('');
	const [hover, setHover] = useState(false);

	const navigate = useNavigate();
  
	useEffect(() => {
	  window.scrollTo(0, 0);
	}, []);

	const handleMobile = (e) => {
		const val = e.target.value.replace(/\D/g, '');
		if (val.length > 10) return;
		setMobile(val);
	}

	const defaultState = () => {
		setName('');
		setMobile('');
		setSelectProduct('');
		setSubject('');
		setDescription('');
	}

	const handleSubmit = async () => {
		const data = {
			name,
			mobile,
			selectProduct,
			subject,
			description
		};
		setLoading(true);
		try {
			await Axios.post('/query/contact-us', { data });
			setOpenAlert(true);
			setAlertType('success');
			setAlertMsg('Query has been sent SuccessFully!');
			defaultState();
		} catch (e) {
			const { data } = e;
			setOpenAlert(true);
			setAlertType('error');
			setAlertMsg('Something went wrong!');
		} finally {
			setLoading(false);
			setTimeout(() => {
				setOpenAlert(false);
			}, 6000);
		}
	}

	const isValid = name &&
		mobile.length === 10 &&
		selectProduct &&
		subject &&
		description;

	return (
	  <Box className={classes.main}>
		  <BackDrop open={loading} />
		  <Snackbar
			  style={{
				  width: '100%',
				  margin: window.innerWidth < 900 ? '20px 0' : '20px 25%',
				  display: 'flex',
				  justifyContent: 'center',
			  }}
			  anchorOrigin={{
				  vertical: "top",
				  horizontal: "center"
			  }}
			  open={openAlert}
		  >
			  <Alert severity={alertType}>{alertMsg}</Alert>
		  </Snackbar>
		  <Grid container className={classes.container}>
			  <img src={ContactUsImg} className={classes.imgStyle} alt="Welcome to MF" />
			  <Box className={classes.contactContainer}>
				  <Box className={classes.boxContainer}>
					  <Box className={classes.boxChild}>
						  <Typography className={classes.header}>
							  Welcome to Medra Finvest 🙏
						  </Typography>
						  <Typography className={classes.subtitle}>
							  Please write your query, we will get in touch with you shortly
						  </Typography>
						  <Box style={{ padding: '0.6rem' }}>
						  	<input className={classes.inputStyle} onChange={(e) => setName(e.target.value.toUpperCase().replace(/[^A-Za-z]/g, ''))} value={name} placeholder='Full Name' />
							<Box>
								<span className={classes.prefix}>+ 91 {' '}</span>
								<input style={{ paddingLeft: '3.3rem', width: 'calc(100% - 68px)', }} value={mobile} className={classes.inputStyle} onChange={handleMobile} placeholder='Mobile no.' />
							</Box>
							<select onChange={(e) => setSelectProduct(e.target.value)} style={{ cursor: 'pointer', width: 'calc(100% + 3px)' }} className={classes.inputStyle}>
								<option value=''>Select Product</option>
								{
									allProduct.map((item, ind) => (
										<option value={item} key={ind}>
											{item}
										</option>
									))
								}
							</select>
							{
								selectProduct === 'InterCorporate Deposit' && (
									<Box style={{ marginTop: '0.7rem' }}>
										<span style={{ color: '#FF8300', fontWeight: 700 }}>Fill the form for ICD - </span> 
										<span className={classes.link} onClick={() => navigate(ROUTES.INTER_CORPORATE_DEPOSIT)}>
											Click Here
										</span>
									</Box>
								)
							}
							<input className={classes.inputStyle} onChange={(e) => setSubject(e.target.value)} value={subject} placeholder='Subject' />
							<textarea
								row={8}
								className={classes.textareaStyle}
								onChange={(e) => setDescription(e.target.value)} 
								value={description} placeholder='Describe your query here ...' 
							/>
							<Typography style={{ color: '#696969', fontSize: '0.75rem', marginTop: '0.6rem' }}>
								* Note: All fields are mandatory
							</Typography>
						  </Box>
						  <Button 
							style={{ backgroundColor: !isValid ? '#C5C5C5' : hover && '#474ebb', opacity: hover && 0.8, color: hover && '#000' }}
							onMouseEnter={() => setHover(true)}
							onMouseLeave={() => setHover(false)}
							className={classes.btnStyle}
							onClick={handleSubmit}
							disabled={!isValid}
						   >
							Enquire Now <ArrowRightAltIcon />
						   </Button>
					  </Box>
				  </Box>
			  </Box>
		  </Grid>
	  </Box>
	)
}

export default ContactUsLayout
