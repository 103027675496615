import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  bond: {},
  ICD: {},
  MF: {},
  unlistedStocks: [],
}

export const LandingSlice = createSlice({
  name: 'landing',
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1
    },
    addBondData: (state, action) => {
      state.bond = action.payload;
    },
    addIcdData: (state, action) => {
      state.ICD = action.payload;
    },
    addMFData: (state, action) => {
      state.MF = action.payload;
    },
    addUnlistedData: (state, action) => {
      state.unlistedStocks = action.payload;
    },
  },
})

export const { increment, addBondData, addIcdData, addMFData, addUnlistedData } = LandingSlice.actions;

export default LandingSlice.reducer;