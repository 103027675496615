import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Snackbar, Typography } from '@material-ui/core';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import BackDrop from '../common/BackDrop';
import Axios from '../common/Axios';
import ContactUsFormImg from '../../assets/CustomerSupportImg.jpg'
import ICDContainer from '../LandingPage/ICDContainer.Layout';
import { Alert } from '@mui/material';
import { getListData } from '../common/Api.service';

const useStyles = makeStyles(theme => ({
	container: {
		padding: '2rem',
		'@media screen and (max-width: 599px)': {
			padding: '1rem',
		}
	},
	header: {
		fontFamily: 'Roboto, sans-serif',
		fontSize: '1.5rem',
		color: '#282828',
		fontWeight: 700,
		'@media screen and (max-width: 400px)': {
			fontSize: '1.3rem',
		},
		'@media screen and (max-width: 300px)': {
			fontSize: '1.1rem',
		}
	},
	paraStyle: {
		fontFamily: 'Roboto, sans-serif',
		color: '#4A4A4A',
		fontSize: '0.875rem',
	},
	conatctContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		'@media screen and (max-width: 899px)': {
			flexDirection: 'column',
			alignItems: 'center',
		},
	},
	imgStyle: {
		width: '50%',
		'@media screen and (max-width: 899px)': {
			width: '60%',
		},
		'@media screen and (max-width: 600px)': {
			width: '70%',
		},
		'@media screen and (max-width: 500px)': {
			width: '90%',
		},
	},
	contactDetails: {
		backgroundColor: '#f3f3f3',
		width: '100%',
		margin: 'auto',
		height: 'auto',
		borderRadius: '1rem',
		padding: '1.5rem',
		'@media screen and (min-width: 1050px)': {
			width: '500px',
		},
		'@media screen and (max-width: 899px)': {
			width: '60%',
			height: 'auto',
			marginTop: '1rem',
		},
		'@media screen and (max-width: 700px)': {
			width: '80%',
		},
		'@media screen and (max-width: 550px)': {
			width: '90%',
		},
		'@media screen and (max-width: 450px)': {
			padding: '1rem',
			width: '90%',
			fontSize: '0.6rem',
		},
	},
	prefix: {
		position: 'absolute',
		marginTop: '1.77rem',
		marginLeft: '1rem',
		fontSize: '0.8rem'

	},
	inputStyle: {
		marginTop: '1rem',
		width: 'calc(100% - 30px)',
		height: '40px',
		border: '1px solid #ccc',
		transition: 'all 0.3s ease',
		padding: '0 15px',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	btnStyle: {
		margin: 'auto',
		marginTop: '1.5rem',
		display: 'flex',
		fontWeight: 700,
		padding: '0.5rem 1.5rem',
		textTransform: 'capitalize',
		borderRadius: '8px',
		cursor: 'pointer',
		letterSpacing: '1px',
		color: '#FFF',
		backgroundColor: '#474ebb',
	},
}));

const data = [
	{
		id: 1,
		title: 'Business Loan Comparison',
		body: 'We offer what your company need. With our wide business lending services, MEDRA FINVEST is able to recognize, respond to, and eliminate your demand for finance. By using cutting-edge comparative research of business loan interest rates, we help our clients select the best lenders from a long range of public and private banks and NBFCs, tailoring business loans for startups, MSMEs, and bigger organizations. Only at MEDRA FINVEST will you receive quicker loan processing and simple disbursement with less documentation to propel your business to new height.',
		para: true,
		bulletPoints: false,
		Numbering: false,
		bulletData: [],
		numberingData: [],
	},
	{
		id: 2,
		title: 'Features and Benefits of Business Loans',
		body: 'To assist borrowers in meeting their financial obligations, business loans offer a variety of advantages and features. When you rely on a business loan, you can access some of the services listed below:',
		para: false,
		bulletPoints: true,
		Numbering: false,
		bulletData: [
			{
				header: 'Fast Disbursal:',
				body: `Typically, business professionals who require immediate access to funds ask for a business loan. A company loan's speedy disbursement is one of its best features. The time it takes to access money is short. The funds are distributed in 3 to 5 business days.`,
			},
			{
				header: 'Minimum Documentation:',
				body: `One of the foremost features of a business loan is the minimal documentation requirement. Banks and finance companies process business applications with little documentation. No hectic paperwork is required. With the online business loan application process, you get the option to submit a scanned copy of the documents, that's all.`,
			},
			{
				header: 'Competitive Business Loan Interest Rates:',
				body: 'Banks and financial organizations provide favorable interest rates on business loans. Debtors can obtain commercial loans with lower monthly installments.',
			},
			{
				header: 'Prepayment Fees:',
				body: 'Prepayment of business loans is not subject to any additional fees or charges. This may differ from one bank to the next.',
			},
			{
				header: 'Flexible Repayment Tenure:',
				body: `In general, Banks and financial institutions, offer business loans with flexible repayment terms. You are free to choose your repayment period. However, keep in mind that a longer repayment period`,
			},
			{
				header: 'Collateral-Free Loans:',
				body: 'The availability of unsecured business loans allows you to get collateral-free business loans. Such loans are quick to get and do not need the pledge of any commercial or personal assets.',
			}
		],
		numberingData: [],
	},
	{
		id: 3,
		title: 'Overview of Business Loans',
		body: <>A business loan is money borrowed by a corporation to pay expenses that it cannot cover on its own. So-called Collateral-Free Loans: You can obtain collateral-free business loans thanks to the availability of unsecured business loans. Such loans are quick to get and do not need the pledge of any commercial or personal assets. While, their new company is getting off the ground, some entrepreneurs utilize business loans to pay for salary and compensation, while others use borrowed funds for office supplies, inventory, business expansion, and business projects. <br /><br /> Lenders want to know how the company intends to spend the borrowed funds, so business owners should create a clear strategy. If you don't appear professional to lenders, your loan application may be declined. <br /><br /> Businesses can choose from a variety of financing options, including company beginning loans, small business loans, and business loans for women. </>,
		para: true,
		bulletPoints: false,
		Numbering: false,
		bulletData: [],
		numberingData: [],
	},
	{
		id: 4,
		title: 'Criteria for Business Loan Eligibility',
		body: 'Banks and credit companies have established business loan eligibility criteria. Stick to the following eligibility conditions to obtain a commercial loan with ease:',
		para: false,
		bulletPoints: true,
		Numbering: false,
		bulletData: [
			{
				header: '',
				body: `Applicants must adhere to the Reserve Bank of India's definition of Micro and Small Businesses.`,
			},
			{
				header: '',
				body: `The sole purpose for getting a business loan must be specified (working cash, business expansion, or the purchase of machinery or equipment).`,
			},
			{
				header: '',
				body: `It is necessary to provide a personal guarantee. As a result, the primary partner will be required to act as a loan guarantor.`,
			},
			{
				header: '',
				body: 'As a result, the main partner will be required to act as a loan guarantor.',
			},
			{
				header: '',
				body: 'The company must have been in business for at least three years in the same industry.',
			},
			{
				header: '',
				body: `Financial documents, such as audited financial accounts, cannot be avoided.`,
			},
			{
				header: '',
				body: 'Tax audit report (for the previous two years).',
			},
			{
				header: '',
				body: 'Bank statement for the business account (for the previous six months).',
			}
		],
		numberingData: [],
	},
	{
		id: 5,
		title: 'Factors Influencing the Interest Rate on a Business Loan',
		body: 'Certain parameters are critical in determining the applicable interest rates. The following factors may influence the interest rates on company loans:',
		para: false,
		bulletPoints: false,
		Numbering: true,
		bulletData: [],
		numberingData: [
			{
				header: 'Nature of Business',
				body: 'The type of business is an important factor in determining the interest rate on your business loan. Your business creditworthiness will determine the type of loan you can obtain and the interest rate. Your company should provide high-quality goods and services. Furthermore, the place where you operate should not be on any blacklists.',
			},
			{
				header: 'CIBIL Score or Credit Score',
				body: 'A high CIBIL score can have a substantial impact on the borrowing rate given. If your CIBIL score is high, the lender will offer you the lowest interest rate loan. If your CIBIL score is low, they may charge you a higher interest rate or even reject your mortgage collateral.',
			},
			{
				header: 'Business Experience',
				body: `The organization's experience is critical since a well-established firm is deemed safe and risk-free. If you've been in business for a while, creditors may be ready to supply you with a low-interest business loan to help you expand.`,
			},
			{
				header: 'Repayment History',
				body: `Your payback history is another essential factor in determining business loan interest rates. You'll have a greater chance of securing a business loan with a lower interest rate if you routinely make your payments on time.`,
			}
		],
	},
]

const DescriptionSection = ({ title, body, bulletPoints, Numbering, bulletData, numberingData, }) => {

	const classes = useStyles();

	return (
		<Box style={{ marginTop: '2rem' }}>
			<Typography className={classes.header}>
				{title}
			</Typography>
			<Typography style={{ marginTop: '1rem' }} className={classes.paraStyle}>
				{body}
			</Typography>
			{
				bulletPoints && (
					<ul>
						{
							bulletData.map((item, ind) => (
								<li key={ind} style={{ marginTop: '0.8rem', color: '#474EBB' }}>
									<Typography style={{ lineHeight: '1.5rem' }} className={classes.paraStyle}>
										<b>{item.header}</b>{'  '}{item.body}
									</Typography>
								</li>
							))
						}
					</ul>
				)
			}
			{
				Numbering && (
					<ol>
						{
							numberingData.map((item, ind) => (
								<li key={ind} style={{ marginTop: '0.8rem' }}>
									<Typography style={{ lineHeight: '1.5rem' }} className={classes.paraStyle}>
										<b style={{ color: '#FD7F20' }}>{item.header}</b>
										<Box style={{ marginTop: '0.5rem' }} />
										{item.body}
									</Typography>
								</li>
							))
						}
					</ol>
				)
			}
		</Box>
	)
}

const ContactUsForm = ({ setLoading, setOpenAlert, setAlertMsg, setAlertType }) => {

	const [name, setName] = useState('');
	const [mobile, setMobile] = useState('');
	const [ownerPan, setOwnerPan] = useState('');
	const [companyPan, setCompanyPan] = useState('');
	const [companyName, setCompanyName] = useState('');
	const [natureOfBusiness, setNatureOfBusiness] = useState('');
	const [tenureOfBusiness, setTenureOfBusiness] = useState('');
	const [paidUpCapital, setPaidUpCapital] = useState('');
	const [state, setState] = useState('');
	const [city, setCity] = useState('');
	const [hover, setHover] = useState(false);

	const classes = useStyles();

	const defaultState = () => {
		setName('');
		setMobile('');
		setOwnerPan('');
		setCompanyPan('');
		setCompanyName('');
		setNatureOfBusiness('');
		setTenureOfBusiness('');
		setPaidUpCapital('');
		setState('');
		setCity('');
	}

	const handleMobile = (e) => {
		const val = e.target.value.replace(/\D/g, '');
		if (val.length > 10) return;
		setMobile(val);
	}

	const handleOwnerPan = (e) => {
		const val = e.target.value.toUpperCase();
		if (val.length > 10) return;
		setOwnerPan(val)
	}

	const handleCompanyPan = (e) => {
		const val = e.target.value.toUpperCase();
		if (val.length > 10) return;
		setCompanyPan(val);
	}

	const handlePaidUpCapital = (e) => {
		const val = e.target.value.replace(/\D/g, '');
		if (val[0] === '0' && val.length >= 2) {
			setPaidUpCapital(val[1]);
			return;
		}
		if (val.length > 8) return;
		setPaidUpCapital(val);
	}

	const handleTenureOfBusiness = (e) => {
		const val = e.target.value.replace(/\D/g, '');
		if (val === '00') return;
		if (val.length >  2) return;
		setTenureOfBusiness(val);
	}

	const handleSubmit = async () => {
		const data = {
			name,
			mobile,
			ownerPan,
			companyPan,
			companyName,
			natureOfBusiness,
			tenureOfBusiness,
			paidUpCapital,
			state,
			city,
		};
		setLoading(true);
		try {
			await Axios.post('/icd/contact-us', { data });
			setOpenAlert(true);
			setAlertMsg('Query has been sent Successfully!');
			setAlertType('success');
			defaultState();
		} catch (e) {
			const { data } = e;
			setOpenAlert(true);
			setAlertMsg('Something went wrong!');
			setAlertType('error');
		} finally {
			setLoading(false);
			setTimeout(() => {
				setOpenAlert(false);
			}, 6000);
		}
	}

	const isValid = 
		name && 
		mobile.length === 10 && 
		ownerPan.length === 10 &&
		companyPan.length === 10 && 
		companyName && 
		natureOfBusiness && 
		tenureOfBusiness && 
		paidUpCapital && 
		state && 
		city;

	return (
		<Box style={{ marginTop: '1rem' }}>
			<Typography className={classes.header}>
				Need A Loan Assistant?
			</Typography>
			<hr color='#1616FF' style={{ width: '100px', margin: '1rem 0' }} />
			<Box className={classes.conatctContainer}>
				<img className={classes.imgStyle} src={ContactUsFormImg} alt="Business Loan" />
				<Box className={classes.contactDetails}>
					<input className={classes.inputStyle} onChange={(e) => setName(e.target.value.toUpperCase().replace(/[^A-Za-z]/g, ''))} value={name} placeholder='Full Name' />
					<Box>
						<span className={classes.prefix}>+ 91 {' '}</span>
						<input style={{ paddingLeft: '3.3rem', width: 'calc(100% - 68px)', }} value={mobile} className={classes.inputStyle} onChange={handleMobile} placeholder='Mobile no.' />
					</Box>
					<input className={classes.inputStyle} onChange={handleOwnerPan} value={ownerPan} placeholder='Owner PAN No.' />
					<input className={classes.inputStyle} onChange={handleCompanyPan} value={companyPan} placeholder='Company PAN No.' />
					<input className={classes.inputStyle} onChange={(e) => setCompanyName(e.target.value.toUpperCase())} value={companyName} placeholder='Company Name' />
					<input className={classes.inputStyle} onChange={(e) => setNatureOfBusiness(e.target.value.toUpperCase())} value={natureOfBusiness} placeholder='Nature or Type of Business' />
					<input className={classes.inputStyle} onChange={handleTenureOfBusiness} value={tenureOfBusiness} placeholder='Tenure of Business' />
					<input className={classes.inputStyle} onChange={handlePaidUpCapital} value={paidUpCapital} placeholder='Paid Up Capital' />
					<input className={classes.inputStyle} onChange={(e) => setState(e.target.value.toUpperCase())} value={state} placeholder='State' />
					<input className={classes.inputStyle} onChange={(e) => setCity(e.target.value.toUpperCase())} value={city} placeholder='City' />
					<Typography style={{ color: '#696969', fontSize: '0.75rem', marginTop: '0.6rem' }}>
						* Note: All fields are mandatory
					</Typography>
					<Button 
						style={{ backgroundColor: !isValid ? '#C5C5C5' : hover && '#474ebb', opacity: hover && 0.8, color: hover && '#000' }}
						onMouseEnter={() => setHover(true)}
						onMouseLeave={() => setHover(false)}
						className={classes.btnStyle}
						onClick={handleSubmit}
						disabled={!isValid}
					>
						Enquire Now <ArrowRightAltIcon />
					</Button>
				</Box>
			</Box>
		</Box>
	)
}

const ICDPageLayout = ({ redirectY = 0 }) => {

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const dispatch = useDispatch();

  const icdData = useSelector((state) => state.landing.ICD);

  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  }

  useEffect(() => {
	window.scrollTo(0, redirectY);
  }, []);

  useEffect(() => {
	if (isEmpty(icdData)) getListData(dispatch);
  }, []);

  return (
	<>
		<BackDrop open={loading} />
		<Snackbar 
			style={{ 
				width: '100%',
				margin: window.innerWidth < 900 ? '10px 0' : '10px 13%', 
				display: 'flex', 
				justifyContent: 'center' 
			}} 
			open={openAlert} 
			anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
		>
			<Alert severity={alertType}>{alertMsg}</Alert>
		</Snackbar>
		<Box className={classes.container}>
			<Typography className={classes.header}>
				InterCorporate Deposit
			</Typography>
			<hr color='#1616FF' style={{ width: '100px', margin: '1rem 0' }} />
			<Typography className={classes.paraStyle}>
				MEDRA FINVEST provides superior lending solutions to a wide range of clients through comprehensive business finance services. We offer lightning-fast processing of business loans with little documentation and maximum efficacy by bridging the gap between lenders and loan providers.
			</Typography>
			<ICDContainer icdData={icdData} icdPage={true} />
			<ContactUsForm setLoading={setLoading} setOpenAlert={setOpenAlert} setAlertMsg={setAlertMsg} setAlertType={setAlertType} />
			{
				data.map((item, ind) => (
					<DescriptionSection
						key={ind}
						title={item.title}
						body={item.body}
						bulletPoints={item.bulletPoints}
						Numbering={item.Numbering}
						bulletData={item.bulletData}
						numberingData={item.numberingData}
					/>
				))
			}
		</Box>
	</>
  )
}

export default ICDPageLayout;
