import Axios from '../common/Axios';
import { ROUTES } from '../../Route/Routes.constants';
import { addBondData, addIcdData, addMFData, addUnlistedData } from '../redux/LandingReducer/LandingReducer';

export const getListData = async (dispatch) => {
	try {
		const response = await Axios.get(ROUTES.GET_LIST);
		const data = response?.data;
		dispatch(addBondData(data?.bond || {}));
		dispatch(addIcdData(data?.ICD || {}));
		dispatch(addMFData(data?.MF || {}));
		dispatch(addUnlistedData(data?.UnlistedStocks) || []);
		return true;
	} catch (e) {
		return false;
	}
}